<template>

  <div title="Party Actions">
    <b-card>
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Convert Party'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="OpenConvert()"
          ><feather-icon
             icon="PlusIcon"
             class="mr-50"
           />
            <span class="align-middle">Convert Party</span></b-button>
        </div>
      </b-button-toolbar>
    </b-card>
    <b-card title="MID Detail">
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Request Update'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="RequestUpdate()"
          >
            <span class="align-middle">Request Update</span></b-button>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Refresh()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <b-row>
        <b-col md="6">
          <label><b><i>MID Number:</i></b></label>
          <b-form-input
            v-model="mid"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="6">
          <label><b><i>Firm Name:</i></b></label>
          <b-form-input
            v-model="FirmName"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <label><b><i>Address:</i></b></label>
          <b-form-input
            v-model="Address"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <label><b><i>City:</i></b></label>
          <b-form-input
            v-model="City"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="2">
          <label><b><i>Country:</i></b></label>
          <b-form-input
            v-model="Country"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="5">
          <label><b><i>Zip:</i></b></label>
          <b-form-input
            v-model="Zip"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>

    </b-card>
    <b-card title="Query Error">
      <AgGridNoEdits
        ref="MIDDetailGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
    </b-card>

    <b-modal
      id="ConvertPartyModal"
      ref="ConvertyMID"
      title="Convert MID"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="ConvertRules"
        tag="form"
      >
        <b-row>
          <b-col md="12">
            <p>Please select the customer for which you want to create the MID.</p>
            <p>Please note, this will create a party in "Parties" Module. You may change the details after conversion in such module.</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Customer"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Customer:</b></i></label>
                  <v-select
                    id="CustomerOption"
                    v-model="CustomerId"
                    :options="CustomerOptions"
                    label="name"
                    placeholder="Select a Customer"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="Convert()"
          >
            <span class="align-middle">Convert</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    AgGridNoEdits,
  },
  data() {
    return {
      required,
      CustomerOptions: [],
      mid: this.$route.params.mid,
      CustomerId: '',
      FirmName: '',
      Address: '',
      City: '',
      Country: '',
      Zip: '',
      aggrid: [],
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('MIDDetail')
    this.aggrid.columnDefs = [
      {
        headerName: 'Error Code', field: 'errorCode', minWidth: 100,
      },
      {
        headerName: 'Message', field: 'message', minWidth: 400,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.MIDDetailGrid.saveState('MIDDetail') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.MIDDetailGrid.saveState('MIDDetail') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.MIDDetailGrid.saveState('MIDDetail') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.MIDDetailGrid.saveState('MIDDetail') }
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadCustomerList(), this.LoadMID(), this.LoadError()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abicustomer/GetCustomerCodes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    Refresh() {
      this.LoadAll()
    },
    LoadMID() {
      return axios.get(`/abi/query/mid/${this.mid}`)
        .then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.FirmName = respdata.firmName
          this.Address = respdata.address
          this.City = respdata.city
          this.Country = respdata.country
          this.Zip = respdata.zip
        })
    },
    LoadError() {
      return axios.get(`/abi/query/mid/${this.mid}/error`)
        .then(resp => {
          this.aggrid.rowData = resp.data
        })
    },
    RequestUpdate() {
      this.LoadingStart()
      const MIDQueryModel = {
        MID: this.mid,
      }

      axios.post('/abi/query/mid', MIDQueryModel)
        .then(() => {
          this.LoadingEnd()
          this.DisplaySuccess('Query data has been submitted.').then(() => {
            this.Refresh()
          })
        })
        .catch(e => {
          this.LoadingEnd()
          this.DisplayError('Query cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
        })
    },
    OpenConvert() {
      this.$refs.ConvertyMID.show()
    },
    Convert() {
      this.$refs.ConvertRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const MIDDetailModel = {
            MID: this.mid,
            CustomerId: this.CustomerId.id,
            FirmName: this.FirmName,
            Address: this.Address,
            City: this.City,
            Country: this.Country,
            Zip: this.Zip,
          }
          axios.post('/abi/query/mid/convert', MIDDetailModel).then(() => {
            this.LoadingEnd()
            this.$refs.ConvertyMID.hide()
            this.DisplaySuccess('Party successfully converted, please view the party in "Party" Module.')
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.ConvertyMID.hide()
              this.DisplayError('Failed to convert.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Failed to convert.', 'Please check data requirements, and try again.')
        }
      })
    },
  },
}
</script>
